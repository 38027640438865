import CssBaseline from "@mui/material/CssBaseline";
import { CryptoProvider } from "../context/cryptoContext";
import dynamic from "next/dynamic";
import { SettingsProvider } from "../context/settingsContext";
import { BuySellProvider } from "../context/buySellContext";
import { useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Head from "next/head";
import NoSleep from "nosleep.js";
import axios from "axios";
import { LoadingScreen } from "@/components/LoadingScreen/loadingScreen";
import { MyContextProvider } from "@/context/fundingDataContext";
import { useRouter } from "next/router";
const ToastContainer = dynamic(
  () => import("react-toastify").then((mod) => mod.ToastContainer),
  { ssr: false }
);

const Home = dynamic(() => import("../components/Home/home"), { ssr: false });

export default function Trading() {
  const router = useRouter();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [mode, setMode] = useState<string | undefined>(
    process.env.NEXT_PUBLIC_THEME
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: (e: string) => {
        setMode(e === "darkTheme" ? "dark" : "light");
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode === "dark" ? "dark" : "light",
        },
      }),
    [mode]
  );

  useEffect(() => {
    const noSleep = new NoSleep();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        noSleep.enable();
      } else {
        noSleep.disable();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      noSleep?.disable();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const fetchUser = async () => {
    try {
      // Check if the user data is cached locally to avoid unnecessary API calls

      let retries = 3; // Number of retries
      const retryDelay = 1000; // Retry delay in ms

      while (retries > 0) {
        console.log(`Retries remaining: ${retries}`);
        try {
          const response = await axios.get("/api/auth/fetchUser", {
            headers: { "Cache-Control": "no-cache" },
          });

          console.log(response.data);

          if (response.status === 200) {
            // Cache user data locally
            setIsLogin(true);
            return;
          }
        } catch (err: any) {
          console.error("Fetch user error:", err.message);
        }

        retries -= 1;
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }

      console.error("All retries exhausted. Redirecting to login page.");
      setIsLogin(false);
      router.replace("/login");
    } catch (err: any) {
      console.error("Unexpected error during fetchUser:", err.message);
      setIsLogin(false);
      router.replace("/login");
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleMode = (e: string) => {
    colorMode.toggleColorMode(e);
  };

  if (!isLogin) return <LoadingScreen />;

  return (
    <div>
      <Head>
        <title>whale.fish | Order Management</title>
        <meta property="og:title" content="Trading Page" key="title" />
      </Head>
      <ThemeProvider theme={theme}>
        <ToastContainer theme="colored" />
        <CssBaseline />
        <SettingsProvider>
          <CryptoProvider>
            <BuySellProvider>
              <MyContextProvider>
                <Home mode={handleMode} />
              </MyContextProvider>
            </BuySellProvider>
          </CryptoProvider>
        </SettingsProvider>
      </ThemeProvider>
    </div>
  );
}
