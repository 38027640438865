import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import {
  cancelOrder,
  createIceBergOrder,
  createNewOrder,
  createTWAPOrder,
  getPosition,
} from "../utils/helper";
import {
  fetchAccounts,
  fetchCurrentAlgos,
  fetchCurrentPaused,
  inputAccounts,
  inputAlgos,
  inputPaused,
} from "../utils/localstorage";
import { useCryptoContext } from "./cryptoContext";
import { PreviousHook } from "./reusable-hooks/previousHook";
import dayjs from "dayjs";
import type { Algos, BuySellContextProps } from "@/types";
import { useUserContext } from "./userContext";
import { useSettingsContext } from "./settingsContext";

const BuySellContext = createContext<BuySellContextProps | undefined>(
  undefined
);

// CONTEXT USED IN ALL THE COMPONENTS TO AVOID PROPS DRILLING

let newMinMaxList: any[] = [];
const intervalObj: { [key: string]: NodeJS.Timeout } = {};

interface BuySellProviderProps {
  children: ReactNode;
}

export const BuySellProvider = ({ children }: BuySellProviderProps) => {
  const { selectedMarket } = useCryptoContext();
  const { loggedinUser, saveSettingsToAuth } = useUserContext();
  const { dataKeys } = useSettingsContext();
  const [orderType, setOrderType] = useState<string>("Limit");
  const [type, setType] = useState<string>("Buy");
  const [price, setPrice] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [iceberg, setIceberg] = useState<number>(0);
  const [random, setRandom] = useState<number | undefined>();
  const [remainingHours, setRemainingHours] = useState<number>(0);
  const [remainingMins, setRemainingMins] = useState<number>(0);
  const [btnState, setBtnState] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [orderBook, setOrderBook] = useState<any>();
  const [algoData, setAlgoData] = useState<any[]>([]);
  const [manualMin, setManualMin] = useState<number | undefined>();
  const [manualMax, setManualMax] = useState<number | undefined>();
  const [toggleMinMax, setToggle] = useState<boolean>(false);
  const prevHook = PreviousHook(selectedMarket);

  useEffect(() => {
    // Reset fields when selectedMarket changes
    if (prevHook?.coin !== selectedMarket?.coin) {
      resetForm();
    }

    // Update button state based on order type and other factors
    const isQuantityValid = !!quantity;
    const isPriceValid = !!price;
    const isIcebergValid = !!iceberg;
    const total = price && quantity ? +price * +quantity : 0;

    switch (orderType) {
      case "Limit":
        setBtnState(!(isPriceValid && isQuantityValid));
        break;
      case "Market":
        setBtnState(!isQuantityValid);
        break;
      case "TWAP":
        setBtnState(
          !(
            isQuantityValid &&
            isPriceValid &&
            (remainingHours || remainingMins)
          )
        );
        break;
      default:
        if (toggleMinMax) {
          const isMinMaxValid = manualMin && manualMax && total >= 10;
          setBtnState(
            !(
              isPriceValid &&
              isQuantityValid &&
              isIcebergValid &&
              isMinMaxValid
            )
          );
        } else {
          setBtnState(
            !(isPriceValid && isQuantityValid && isIcebergValid && total >= 10)
          );
        }
        break;
    }
  }, [
    selectedMarket,
    orderType,
    price,
    quantity,
    iceberg,
    toggleMinMax,
    manualMax,
    manualMin,
    remainingHours,
    remainingMins,
  ]);

  const resetForm = () => {
    setPrice("");
    setType("Buy");
    setQuantity("");
    setIceberg(0);
    setToggle(false);
  };

  const checkAvailableApiKeys = useMemo(() => {
    const isBinance = selectedMarket?.market === "binance";
    const isSpecificApiType = isBinance
      ? dataKeys?.apiTypeB
      : dataKeys?.apiTypeD;

    if (isSpecificApiType) {
      const publicKeys = isBinance
        ? dataKeys?.binancePublic_specific
        : dataKeys?.deribitPublic_specific;
      const secretKeys = isBinance
        ? dataKeys?.binanceSecret_specific
        : dataKeys?.deribitSecret_specific;

      return (
        !!publicKeys?.[
          isBinance ? "binanceFuturePublic" : "deribitFuturePublic"
        ] &&
        !!publicKeys?.[
          isBinance ? "binanceSpotPublic" : "deribitOptionPublic"
        ] &&
        !!secretKeys?.[
          isBinance ? "binanceFutureSecret" : "deribitFutureSecret"
        ] &&
        !!secretKeys?.[isBinance ? "binanceSpotSecret" : "deribitOptionSecret"]
      );
    }

    const publicKey = isBinance
      ? dataKeys?.binancePublic
      : dataKeys?.deribitPublic;
    const secretKey = isBinance
      ? dataKeys?.binanceSecret
      : dataKeys?.deribitSecret;

    return !!publicKey && !!secretKey;
  }, [selectedMarket, dataKeys]);

  const handleAccPaused = async (newPaused: any) => {
    const parsedAcc = await fetchAccounts();
    const currentUser = loggedinUser?.sub;

    return parsedAcc.map((account) =>
      account.username === currentUser
        ? { ...account, paused: newPaused }
        : account
    );
  };

  const handleAlgos = async (algos: Algos[]) => {
    setAlgoData(algos);
  };

  const handleToggleMinMax = async (e: boolean, data: any) => {
    setToggle(e);
    const position = await getPosition(data);

    let min, max;

    const existingAlgo = newMinMaxList.find(
      (algo) => algo.instrument === selectedMarket.coin
    );

    if (existingAlgo) {
      ({ min, max } = existingAlgo);
    } else if (position?.error) {
      toast.error(position.error);
      return;
    } else {
      const pos = parseFloat(position?.result?.size || "0");
      const qty = parseFloat(quantity || "1");

      min = Math.min(pos + qty, pos);
      max = Math.max(pos + qty, pos);
    }

    if (e) {
      setManualMin(min);
      setManualMax(max);
    }
  };

  const handleCreateOrder = async (e: any) => {
    const {
      event,
      tab,
      coin,
      keys = {},
      marketType,
      exec,
      qty,
      prc,
      iceQty,
      side,
      mn,
      mx,
      ordType,
      orderID,
      id,
      algoToggleMinMax,
      resumeOrder,
      algoRemainingTime,
    } = e;

    event.preventDefault();
    setBtnState(true);

    const parsedAlgos =
      (await fetchCurrentAlgos(loggedinUser?.sub || "")) || [];
    const ordTypeFinal = ordType || orderType;

    const validateKeys = () => {
      if (tab === "binance") {
        if (keys?.apiTypeB) {
          return (
            keys?.binancePublic_specific?.binanceFuturePublic &&
            keys.binancePublic_specific.binanceSpotPublic &&
            keys.binanceSecret_specific.binanceFutureSecret &&
            keys.binanceSecret_specific.binanceSpotSecret
          );
        } else {
          return keys.binancePublic && keys.binanceSecret;
        }
      } else if (tab === "deribit") {
        if (keys.apiTypeD) {
          return (
            keys?.deribitPublic_specific?.deribitFuturePublic &&
            keys.deribitPublic_specific.deribitSpotPublic &&
            keys.deribitSecret_specific.deribitFutureSecret &&
            keys.deribitSecret_specific.deribitSpotSecret
          );
        } else {
          return keys.deribitPublic && keys.deribitSecret;
        }
      } else {
        return keys.xrplSecret;
      }
    };

    if (!validateKeys()) {
      toast.error(
        `You need to input your ${tab} secret key and public key in the settings.`
      );
      return;
    }

    const formData: any = {
      type: orderType,
      side: type,
      price,
      quantity,
      icebergQty: iceberg,
    };

    let order;

    console.time("Creating an order timelapse");

    if (ordTypeFinal === "Iceberg - Whale" || ordTypeFinal === "TWAP") {
      const position = await getPosition({
        market: tab,
        coin,
        insType: marketType,
      });
      let min = 0,
        max = 0;

      const calculateMinMax = () => {
        const pos = parseFloat(position?.result?.size);
        const qtyVal =
          type === "Sell" ? -parseFloat(quantity) : parseFloat(quantity);

        min =
          Math.min(pos + qtyVal, pos) -
          calculateRemaining(parsedAlgos, coin, "Sell");
        max =
          Math.max(pos + qtyVal, pos) +
          calculateRemaining(parsedAlgos, coin, "Buy");
      };

      if (!toggleMinMax && !resumeOrder) {
        if (position?.error) {
          toast.error(position.error);
          return;
        }
        if (position?.result) {
          calculateMinMax();
          updateMinMaxList(coin, min, max);
        } else {
          toast.warning("We can't get any position with this instrument!");
          return;
        }
      } else if (toggleMinMax && !resumeOrder) {
        min = manualMin || 0;
        max = manualMax || 0;
        updateMinMaxList(coin, min, max);
      }

      min = parseFloat(mn) || min;
      max = parseFloat(mx) || max;

      const updatedAlgos = parsedAlgos.map((item) =>
        item.instrument === coin && (item.resume || item.error)
          ? { ...item, min, max }
          : item
      );

      await inputAlgos({
        uniqueID: loggedinUser?.sub || "",
        algosToSave: updatedAlgos,
      });

      if (parsedAlgos.length) {
        const runningAlgos = parsedAlgos.filter(
          (item) => item.instrument === coin && item.running
        );
        if (runningAlgos.length) {
          toast.info(
            "We suggest that you manually input your min and max. You can set the toggle to manual."
          );
        }
      }

      const currentToggleMinMax = resumeOrder ? algoToggleMinMax : toggleMinMax;
      setQuantity("");

      if (ordTypeFinal === "TWAP") {
        const remainingTime =
          algoRemainingTime || +remainingHours * 3600 + +remainingMins * 60;
        const notional = +price * +quantity;
        const sleeptime = Math.max(
          remainingTime /
            (notional / +(process.env.NEXT_PUBLIC_MIN_TWAP_ORDER || 1)),
          +(process.env.NEXT_PUBLIC_MIN_TWAP_SLEEPTIME || 0)
        );

        order = await createOrder_TWAP_Iceberg(
          tab,
          coin,
          marketType,
          min,
          max,
          exec,
          qty,
          prc,
          iceQty,
          side,
          orderID,
          id,
          currentToggleMinMax,
          "twap",
          sleeptime,
          remainingTime
        );
      } else {
        order = await createOrder_TWAP_Iceberg(
          tab,
          coin,
          marketType,
          min,
          max,
          exec,
          qty,
          prc,
          iceQty,
          side,
          orderID,
          id,
          currentToggleMinMax,
          "iceberg"
        );
      }
    } else {
      order = await createNewOrder(tab, formData, coin, marketType);
    }

    handleOrderResponse(order);
    console.timeEnd("Creating an order timelapse");
  };

  const calculateRemaining = (algos: any, coin: string, side: string) => {
    return algos?.reduce((sum: number, item: any) => {
      if (item.instrument === coin && item.side === side) {
        return item.running || item.error || item.resume
          ? sum + item.quantity - item.qtyFilled
          : sum;
      }
      return sum;
    }, 0);
  };

  const updateMinMaxList = (coin: string, min: number, max: number) => {
    newMinMaxList = newMinMaxList.some((algo) => algo.instrument === coin)
      ? newMinMaxList.map((item) =>
          item.instrument === coin ? { instrument: coin, min, max } : item
        )
      : [...newMinMaxList, { instrument: coin, min, max }];
  };

  const handleOrderResponse = (order: any) => {
    if (order?.error) {
      toast.error(order.error);
      setIceberg(0);
      setPrice("");
      setManualMin(undefined);
      setManualMax(undefined);
    } else if (order?.warning) {
      toast.warning(order.warning);
    } else if (!order) {
      console.error("No return order");
    } else {
      toast.success(`${order?.side === "sell" ? "Sell" : "Order"} success!`);
    }
  };

  const createOrder_TWAP_Iceberg = async (
    tab: string,
    coin: string,
    marketTable: string,
    min: number,
    max: number,
    exec: number,
    qty: number,
    prc: number,
    iceQty: number,
    side: string,
    ordID: string,
    id: number,
    algoToggleMinMax: boolean,
    ordType: string,
    sleeptime?: number,
    remainingTime?: number
  ) => {
    let algos = (await fetchCurrentAlgos(loggedinUser?.sub || "")) || [];
    const pause = (await fetchCurrentPaused(loggedinUser?.sub || "")) || [];

    let orderID = "";
    let totalNotional = 0;
    let firstOrder = true;
    let executed = exec ? +exec : 0;

    const newPrice = prc ? +prc : +price;
    const newQty = qty ? +qty : +quantity;
    const newIceQty = iceQty ? +iceQty : +iceberg;
    const newDir = side || type;

    let newRemainingTime = remainingTime || 0;
    let secondsDiff = 1000;

    const orderOption = {
      algo_name: ordType === "iceberg" ? "Iceberg" : "Twap",
      random:
        ordType === "iceberg"
          ? 0.5
          : +(process.env.NEXT_PUBLIC_TWAP_RANDOM || 0),
      market_name: tab,
      instrument_name: coin,
      quantity: newQty,
      price: newPrice,
      iceberg_quantity: newIceQty,
      type: "limit",
      direction: newDir,
      totalExecuted: executed,
      sleep_time: ordType === "twap" ? +(sleeptime || 0) : undefined,
      remaining_time: ordType === "twap" ? newRemainingTime : undefined,
    };

    if (ordID) {
      algos = algos.filter((item: any) => item.orderID !== ordID); // remove the previous order
    }

    if (id) {
      const filPause = pause.filter((item: any) => +item !== +id);
      const fil = algos.filter((item: any) => +item.id !== +id);

      const paused = await handleAccPaused(filPause);

      await inputPaused({
        uniqueID: loggedinUser?.sub || "",
        idsToBePaused: [...pause, id],
      });

      await inputAccounts(paused);
      algos = fil;
    }

    let exit = false;
    const locID = loggedinUser?.metadata?.user_metadata?.lastID;
    const newID = id || (locID ? +locID + 1 : 1);

    if (!id) {
      await saveSettingsToAuth(newID, "lastID");
    }

    min = min ? +min : min;
    max = max ? +max : max;

    const fetchOrderNow = async () => {
      if (!firstOrder) {
        const filter = newMinMaxList.find((item) => item.instrument === coin);
        if (filter) {
          min = filter.min || min;
          max = filter.max || max;
        }
      }

      if (ordType === "twap") {
        orderOption.remaining_time = newRemainingTime;

        console.log("Remaining time: ", orderOption.remaining_time);

        if (newRemainingTime <= 0) {
          console.log(
            "Remaining time is 0 or less, checking final execution status."
          );
          if (executed < newQty) {
            console.log("Final slice needed, executing remaining quantity.");
            return "final-slice"; // Return a custom identifier for the final slice
          } else {
            return {
              error: "Remaining time is 0 or less, no more orders to create.",
            };
          }
        }

        console.log("Remaining time: ", orderOption.remaining_time);
      }

      const timeBeforeFetch = dayjs();

      const order =
        ordType === "iceberg"
          ? await createIceBergOrder(
              tab,
              coin,
              marketTable,
              min,
              max,
              orderOption,
              orderID,
              firstOrder,
              newID,
              loggedinUser
            )
          : await createTWAPOrder(
              tab,
              coin,
              marketTable,
              min,
              max,
              orderOption,
              orderID,
              firstOrder,
              newID,
              loggedinUser
            );

      console.log("Order: ", order);

      const timeAfterFetch = dayjs();
      secondsDiff = timeAfterFetch.diff(timeBeforeFetch, "milliseconds");
      const newAlgos = (await fetchCurrentAlgos(loggedinUser?.sub || "")) || [];
      algos = newAlgos.filter((item: any) => +item.id !== +newID);

      if (ordType === "twap") {
        newRemainingTime -= sleeptime || 0;

        console.log(newRemainingTime);
      }

      if (order?.error) {
        handleSetAlgo(
          algos,
          orderID,
          executed,
          coin,
          newPrice,
          newQty,
          newDir,
          newIceQty,
          min,
          max,
          undefined,
          undefined,
          tab,
          marketTable,
          "resume",
          order?.error,
          newID,
          algoToggleMinMax,
          newRemainingTime,
          sleeptime || 0,
          ordType
        );
        return { warning: order?.error };
      } else if (order?.warning) {
        handleSetAlgo(
          algos,
          orderID,
          executed,
          coin,
          newPrice,
          newQty,
          newDir,
          newIceQty,
          min,
          max,
          undefined,
          undefined,
          tab,
          marketTable,
          "resume",
          order.warning,
          newID,
          algoToggleMinMax,
          newRemainingTime,
          sleeptime || 0,
          ordType
        );
        return { warning: order.warning };
      } else {
        orderID = order?.info?.order_id;
        executed += order?.filled;
        totalNotional += order?.filled * order?.info?.average_price;
        orderOption.totalExecuted = executed;
        firstOrder = false;
        const avgPrice = totalNotional / executed;
        exit = order?.exit;
        const running =
          ordType === "twap" ? newRemainingTime !== 0 : executed !== +newQty;
        const resume = running;

        handleSetAlgo(
          algos,
          orderID,
          executed,
          coin,
          newPrice,
          newQty,
          newDir,
          newIceQty,
          min,
          max,
          avgPrice,
          running,
          tab,
          marketTable,
          resume,
          null,
          newID,
          algoToggleMinMax,
          newRemainingTime,
          sleeptime || 0,
          ordType
        );

        return "success";
      }
    };

    if (ordType === "iceberg") {
      if (executed >= orderOption.quantity) {
        handleSetAlgo(
          algos,
          orderID,
          executed,
          coin,
          newPrice,
          newQty,
          newDir,
          newIceQty,
          min,
          max,
          0,
          false,
          tab,
          marketTable,
          false,
          null,
          newID,
          algoToggleMinMax,
          newRemainingTime,
          sleeptime || 0,
          ordType
        );

        return "success";
      }

      while (executed < orderOption.quantity && !exit) {
        const order: any = await fetchOrderNow();
        if (order?.error || order?.warning) {
          return order;
        }
      }
    } else {
      const newSleepTime = +(sleeptime || 0) * 1000;

      let orderExecuted = false;

      while (!exit && newRemainingTime > 0) {
        const order: any = await fetchOrderNow();
        if (order === "final-slice") {
          console.log("Executing final slice.");
          const finalOrder = await createTWAPOrder(
            tab,
            coin,
            marketTable,
            min,
            max,
            orderOption,
            orderID,
            false,
            newID,
            loggedinUser
          );
          if (finalOrder?.error || finalOrder?.warning) {
            return finalOrder;
          }
          orderExecuted = true;
          break;
        }
        if (order?.error || order?.warning) {
          return order;
        }

        if (order === "duplicate") {
          return;
        }

        orderExecuted = true;

        // Sleep for the next interval if more time remains
        if (newRemainingTime > 0) {
          await new Promise((resolve) =>
            setTimeout(resolve, newSleepTime - (firstOrder ? 0 : secondsDiff))
          );
        }
      }

      // Handle the final slice if the loop ends without executing it
      if (!orderExecuted && newRemainingTime <= 0 && executed < newQty) {
        console.log(
          "Final order slice after loop, remaining time is 0 or less."
        );
        await fetchOrderNow();
      }

      if (newRemainingTime <= 0) {
        return "success";
      }
    }
  };

  const handleSetAlgo = async (
    algos: any[],
    orderID: string | undefined,
    executed: number,
    coin: string,
    price: number,
    quantity: number,
    type: string,
    iceberg: number,
    min: number,
    max: number,
    avgPrice: number | undefined,
    running: boolean | undefined,
    tab: string,
    marketTable: string,
    resume: boolean | string | null,
    error: string | null,
    newID: number,
    algoToggleMinMax: boolean,
    newRemainingTime: number,
    sleeptime: number,
    ordType: string
  ) => {
    const newAlgo = {
      orderID,
      id: newID,
      instrument: coin,
      price,
      quantity,
      side: type,
      icebergQty: iceberg,
      min,
      max,
      qtyFilled: executed,
      avgPrice: avgPrice && isFinite(avgPrice || 0) ? avgPrice.toFixed(2) : 0,
      running,
      tab,
      marketTable,
      resume,
      error,
      toggleMinMax: algoToggleMinMax,
      newRemainingTime,
      sleeptime,
      orderType: ordType,
    };

    const newAlgos: Algos[] = algos ? [...algos, newAlgo] : [newAlgo];

    await inputAlgos({
      uniqueID: loggedinUser?.sub || "",
      algosToSave: newAlgos,
    });

    handleAlgos(newAlgos);
  };

  const updateMinMaxAllInstrument = (e: any) => {
    const { instrument, min, max, qty, price, iceberg } = e;

    newMinMaxList = newMinMaxList.map((item) =>
      item.instrument === instrument
        ? {
            instrument,
            min: min || item.min,
            max: max || item.max,
            quantity: qty || item.quantity,
            price: price || item.price,
            icebergQty: iceberg || item.icebergQty,
          }
        : item
    );

    if (!newMinMaxList.some((item) => item.instrument === instrument)) {
      newMinMaxList.push({ instrument, min, max });
    }
  };

  const handleCancelOrder = async (e: any) => {
    try {
      const order = await cancelOrder(e);

      if (order?.error) {
        toast.error(order.error);
        return;
      }

      toast.success("Order successfully canceled.");

      const algosFromLS = await fetchCurrentAlgos(loggedinUser?.sub || "");

      const updatedAlgos = algosFromLS.filter(
        (item) => item.orderID !== order?.result?.order_id
      );

      saveSettingsToAuth(updatedAlgos, "algos");

      await inputAlgos({
        uniqueID: loggedinUser?.sub || "",
        algosToSave: updatedAlgos,
      });

      handleAlgos(updatedAlgos);
    } catch (error) {
      toast.error("An error occurred while canceling the order.");
      console.error(error);
    }
  };

  const handleClear = async (id: number) => {
    const algosFromLS =
      (await fetchCurrentAlgos(loggedinUser?.sub || "")) || [];
    const pausedFromLS =
      (await fetchCurrentPaused(loggedinUser?.sub || "")) || [];

    if (!algosFromLS.length) return;

    const filteredAlgos = algosFromLS.filter((item) => item.id !== +id);

    newMinMaxList = newMinMaxList.filter((item) =>
      filteredAlgos.some((algo) => algo.instrument === item.instrument)
    );

    const newPaused = pausedFromLS.filter((item: any) => item !== +id);

    saveSettingsToAuth(filteredAlgos, "algos");
    saveSettingsToAuth(newPaused, "paused");

    await inputAlgos({
      uniqueID: loggedinUser?.sub || "",
      algosToSave: filteredAlgos,
    });

    await inputPaused({
      uniqueID: loggedinUser?.sub || "",
      idsToBePaused: newPaused,
    });

    toast.success("Successfully cleared an algo!");

    handleAlgos(filteredAlgos);
  };

  const handlePause = async (id: number) => {
    const algosFromAuth = loggedinUser?.metadata?.user_metadata?.algos;
    const algosFromLS = await fetchCurrentAlgos(loggedinUser?.sub || "");
    const parsedAlgos = algosFromLS.length ? algosFromLS : algosFromAuth;

    const updatedAlgos = parsedAlgos.map((item: any) =>
      item.id === +id ? { ...item, resume: true, running: false } : item
    );

    clearInterval(intervalObj[`interval${id}`]);

    await inputAlgos({
      uniqueID: loggedinUser?.sub || "",
      algosToSave: updatedAlgos,
    });

    const pausedFromLS =
      (await fetchCurrentPaused(loggedinUser?.sub || "")) || [];

    await inputPaused({
      uniqueID: loggedinUser?.sub || "",
      idsToBePaused: [...pausedFromLS, id],
    });

    saveSettingsToAuth(updatedAlgos, "algos");
    saveSettingsToAuth([...pausedFromLS, id], "paused");

    handleAlgos(updatedAlgos);
  };

  const handlePriceQty = (val: string, typ: string) => {
    const total = typ === "price" ? +val * +quantity : +val * +price;

    if (total < 10 && orderType !== "Market") {
      const errorArr = errors.filter(
        (item) => item === "(Quantity * Price) should total at least 10"
      );
      if (!errorArr.length) {
        setErrors([...errors, "(Quantity * Price) should total at least 10"]);
      }
      setBtnState(true);
    } else {
      const errorArr = errors.filter(
        (item) => item !== "(Quantity * Price) should total at least 10"
      );

      setErrors(errorArr);
    }
  };

  const handleOrderBook_table = (data: any, event: any, tdType: string) => {
    if (event.detail === 2) {
      if (tdType === "price") {
        setPrice(data.price);
      } else {
        setQuantity(data.quantity);
      }
    }
  };

  const handleWarningLive = async (e: string, data: any, tog: boolean) => {
    if (!tog) {
      const algosFromLS = await fetchCurrentAlgos(loggedinUser?.sub || "");
      const filterAlgo = algosFromLS.filter(
        (item: any) =>
          item.instrument === data.coin && (item.running || item.resume)
      );

      if (filterAlgo.length && e === "Iceberg - Whale") {
        toast.info(
          "We suggest that you manually input your min and max. You can set the toggle to manual."
        );
      }
    }
  };

  return (
    <BuySellContext.Provider
      value={{
        orderType,
        type,
        iceberg,
        btnState,
        price,
        quantity,
        errors,
        orderBook,
        algoData,
        toggleMinMax,
        manualMin,
        manualMax,
        random,
        remainingHours,
        remainingMins,
        checkAvailableApiKeys,
        setRemainingMins,
        setRemainingHours,
        setRandom,
        updateMinMaxAllInstrument,
        setToggle,
        setManualMin,
        setManualMax,
        setErrors,
        setOrderBook,
        setOrderType,
        setBtnState,
        setIceberg,
        setPrice,
        setQuantity,
        setType,
        handleCreateOrder,
        handlePriceQty,
        handleAlgos,
        handleCancelOrder,
        handleClear,
        handleToggleMinMax,
        handlePause,
        handleWarningLive,
        handleOrderBook_table,
      }}
    >
      {children}
    </BuySellContext.Provider>
  );
};

export const useBuySellContext = () => {
  const context = useContext(BuySellContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
