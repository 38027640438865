"use client";
import axios from "axios";
import {
  MRT_ColumnFiltersState,
  MRT_GroupingState,
  MRT_SortingState,
  MRT_VisibilityState,
} from "material-react-table";
import dayjs from "dayjs";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import useSWR, { SWRResponse } from "swr";
import {
  dashItems,
  tableObject,
  underlyingObj,
  exchangeToggle,
  FundingInputs,
  chartObject,
  CounterState,
  xAxisObject,
  averageObj,
  DashboardList,
  MyContextPropsFunding,
  fundingFieldsTypes,
} from "@/types";
import { useUserContext } from "./userContext";

const MyContext = createContext<MyContextPropsFunding | undefined>(undefined);

interface MyContextProviderProps {
  children: ReactNode;
}

const fetcher = async ({ tblName, underlying }: any) => {
  const url = `/api/routers/fetchlaser`;
  const params = {
    underlying,
    table: tblName,
    filters: JSON.stringify({}),
    columns: JSON.stringify([]),
    orderBy: JSON.stringify({}),
  };

  const response = await axios.get(url, { params });

  return response.data;
};

export const MyContextProvider: React.FC<MyContextProviderProps> = ({
  children,
}) => {
  const { loggedinUser, path, saveSettingsToAuth } = useUserContext();
  const [tableData, setTableData] = useState<tableObject[]>([]);
  const [underlyingValues, setUnderlyingValues] = useState<underlyingObj[]>([]);
  const [dashboardItems, setDashboardItems] = useState<dashItems[]>([]);
  const [togglePrice, setTogglePrice] = useState(false);
  const [underlying, setUnderlying] = useState<string[]>([]);
  const [selectedUnderlying, setSelectedUnderlying] = useState<string>("");
  const [favorites, setFavorites] = useState<string[]>([]);
  const [fundingFields, setFundingFields] = useState<fundingFieldsTypes>({
    days: "30",
    a: "100",
    b: "100",
    c: "0",
    d: "0",
    x: "33.3",
    y: "33.3",
    z: "33.3",
    prevX: "33.3",
    prevY: "33.3",
    prevZ: "33.3",
  });
  const [dashLoading, setDashLoading] = useState<boolean>(true);
  const [annualize, setAnnualize] = useState<boolean>(false);
  const [fundingChart, setFundingChart] = useState<chartObject[]>([]);
  const [stakingChart, setStakingChart] = useState<chartObject[]>([]);
  const [priceReturnChart, setPriceReturnChart] = useState<chartObject[]>([]);
  const [totalReturnChart, setTotalReturnChart] = useState<chartObject[]>([]);
  const [tableHeight, setTableHeight] = useState<CounterState | undefined>();
  const [graphHeight, setGraphHeight] = useState<CounterState | undefined>();
  const [selectedGraphTable, setSelectedGraphTable] = useState<string>("");
  const [areChanges, setAreChanges] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [xAxis, setXAxis] = useState<xAxisObject>({
    min: undefined,
    max: undefined,
  });
  const [processedFundingData, setProcessedFundingData] = useState<
    tableObject[]
  >([]);

  const [fundingInputs, setFundingInputs] = useState<FundingInputs>({
    binance: false,
    bybit: false,
    okx: false,
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {
      FundingDaily: true,
      StakingDaily: true,
      Funding_OKX: true,
      Funding_Bybit: true,
      Funding_Binance: true,
      Price_Binance: false,
      Price_Bybit: false,
      Price_OKX: false,
      Staking: false,
      Funding: false,
      PriceReturn: false,
      TotalReturn: true,
      Date: true,
    }
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>(
    undefined
  );
  const [showGlobalFilter, setShowGlobalFilter] = useState<boolean>(false);
  const [showColumnFilters, setShowColumnFilters] = useState<boolean>(false);
  const [grouping, setGrouping] = useState<MRT_GroupingState>([]);
  const [dashboardList, setDashboardList] = useState<DashboardList[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState<string | null>(
    null
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: "Date",
      desc: true,
    },
  ]);
  const [avgWeight, setAvgWeight] = useState<boolean>(true);

  const [exchangeToggle, setExchangeToggle] = useState<exchangeToggle>({
    binance: true,
    bybit: true,
    okx: true,
  });

  const { data: fundingValues }: SWRResponse<any> = useSWR(
    { tblName: "StakingStatic", underlying: "" },
    path === "analytics" ? fetcher : null,
    {
      revalidateOnFocus: true,
    }
  );

  const { data: fundingData }: SWRResponse<any> = useSWR(
    {
      tblName: "StakingFundingDataComplete",
      underlying: selectedUnderlying || "",
    },
    path === "analytics" ? fetcher : null,
    {
      revalidateOnFocus: true,
    }
  );

  useEffect(() => {
    if (loggedinUser) {
      const d = loggedinUser?.metadata?.user_metadata;

      setDashboardList(d?.fundingDashboards || []);
      setSelectedUnderlying(d?.selectedUnderlying || "");
      setSelectedDashboard(d?.selectedDashboard || "");
      setFundingFields({
        days: d?.fundingDays || "30",
        a: d?.fundingA || "100",
        b: d?.fundingB || "100",
        c: d?.fundingC || "0",
        d: d?.fundingD || "0",
        x: d?.fundingX || "33.3",
        y: d?.fundingY || "33.3",
        z: d?.fundingZ || "33.3",
        prevX: d?.fundingX || "33.3",
        prevY: d?.fundingY || "33.3",
        prevZ: d?.fundingZ || "33.3",
      });

      setAvgWeight(d?.fundingAvgWeight ?? true);
      setExchangeToggle(
        d?.exchangeToggle || {
          binance: true,
          bybit: true,
          okx: true,
        }
      );
      setDashLoading(false);
    }
  }, [loggedinUser]);

  useEffect(() => {
    let counter = 290;
    let counterGraph = 200;

    let counterObj = { 1: 80, 2: 100, 3: 135, 4: 200, 5: 290 };
    let counterObjGraph = { 4: 220, 5: 250 };

    for (let i = 6; i < 45; i++) {
      counterObj = { ...counterObj, [i]: (counter += 70) };
      counterObjGraph = { ...counterObjGraph, [i]: (counterGraph += 85) };
    }

    setGraphHeight(counterObjGraph);
    setTableHeight(counterObj);
  }, []);

  useEffect(() => {
    setXAxis({ min: undefined, max: undefined });
    setFundingChart([]);
    setStakingChart([]);
    setPriceReturnChart([]);
    setTotalReturnChart([]);
    setTableData([]);
    setLoading(true);
  }, [selectedUnderlying]);

  useEffect(() => {
    setFundingInputs(checkFundingNull(selectedUnderlying));
  }, [underlyingValues, selectedUnderlying]);

  useEffect(() => {
    if (fundingData) {
      setTableData(fundingData);
    }
  }, [fundingData]);

  useEffect(() => {
    if (fundingValues && !fundingValues.error) {
      setUnderlyingValues(fundingValues);
      setUnderlying(
        fundingValues?.length
          ? fundingValues
              .map((item: underlyingObj) => item?.PrimaryID)
              .sort((a: string, b: string) => (a > b ? 1 : -1))
          : []
      );
      setSelectedUnderlying(
        (prevState) => prevState || fundingValues[0]?.PrimaryID
      );
    }
  }, [fundingValues]);

  useEffect(() => {
    const nonNull = Object.keys(fundingInputs).reduce((accumulator, key) => {
      const value = key as "binance" | "bybit" | "okx";
      return (accumulator +=
        fundingInputs[value] && exchangeToggle[value] ? 1 : 0);
    }, 0);

    const finalVal = calcValBasedExchange(nonNull);

    const updateState = (toggle: boolean, type: string) => {
      setFundingFields((prevState) => ({
        ...prevState,
        [type]: toggle ? `${finalVal}` : "0",
      }));
    };

    updateState(exchangeToggle.binance, "x");
    updateState(exchangeToggle.bybit, "y");
    updateState(exchangeToggle.okx, "z");
  }, [avgWeight, fundingInputs, exchangeToggle]);

  const calcValBasedExchange = (nonNull: number) => {
    const defaultVal = 100 / nonNull;
    const finalVal = nonNull === 3 ? defaultVal.toFixed(1) : defaultVal;

    return +finalVal;
  };

  const resizeUpdateDashboard = (newArray: any) => {
    const newDashItems: dashItems[] = [];

    if (newArray && newArray.length) {
      for (const item of newArray) {
        const filterDash = dashboardItems.filter((it) => it.id === item.i);
        const obj: any = filterDash.length
          ? { ...filterDash[0], h: item.h }
          : {};

        obj.w = item.w;
        obj.x = item.x;
        obj.y = item.y;
        obj.i = item.i;

        newDashItems.push(obj);
      }

      updateDashboardItems(newDashItems, "updateDash");
    }
  };

  const checkFundingNull = (underlying: string) => {
    const filterUnderlying = underlyingValues?.filter(
      (item: underlyingObj) => item?.PrimaryID === underlying
    )[0];

    const info =
      filterUnderlying?.FutureInfo || filterUnderlying?.futureInfo || "";

    let exchangeSupports = filterUnderlying
      ? typeof info === "object"
        ? info
        : JSON.parse(info)
      : {};

    exchangeSupports =
      typeof exchangeSupports === "string"
        ? JSON.parse(exchangeSupports)
        : exchangeSupports;

    return {
      binance: !!exchangeSupports.binance?.symbol,
      bybit: !!exchangeSupports.bybit?.symbol,
      okx: !!exchangeSupports.okx?.symbol,
    };
  };

  const checkFundingNullIndividual = (
    binance: number | null,
    bybit: number | null,
    okx: number | null
  ) => {
    const exchangeSupports: number[] = [];
    if (binance !== null) {
      exchangeSupports.push(binance);
    }
    if (bybit !== null) {
      exchangeSupports.push(bybit);
    }
    if (okx !== null) {
      exchangeSupports.push(okx);
    }

    return exchangeSupports.length;
  };

  const addToDashboard = (
    e: string,
    dash: any,
    chartType: string | undefined,
    id: string
  ) => {
    const minColsGraph = process.env.NEXT_PUBLIC_ANALYTICS_DASH_MINCOLS_GRAPH;
    const minColsTable = process.env.NEXT_PUBLIC_ANALYTICS_DASH_MINCOLS_TABLE;
    const maxRowsGraph = process.env.NEXT_PUBLIC_ANALYTICS_DASH_MAXROWS_GRAPH;
    const maxRowsTable = process.env.NEXT_PUBLIC_ANALYTICS_DASH_MAXROWS_TABLE;
    const minRows = process.env.NEXT_PUBLIC_ANALYTICS_DASH_MINROWS;

    dash.push({
      id,
      name: e,
      days: fundingFields.days,
      a: fundingFields.a,
      b: fundingFields.b,
      c: fundingFields.c,
      d: fundingFields.d,
      binance: fundingFields.x,
      bybit: fundingFields.y,
      okx: fundingFields.z,
      annualize,
      order: dash.length,
      underlying: selectedUnderlying,
      y: 0,
      w: 4,
      h: 5,
      i: id,
      chartType,
      minW:
        e !== "table"
          ? minColsGraph
            ? +minColsGraph
            : 4
          : minColsTable
          ? +minColsTable
          : 3,
      minH: minRows ? +minRows : 4,
      maxH:
        e !== "table"
          ? maxRowsGraph
            ? +maxRowsGraph
            : 10
          : maxRowsTable
          ? +maxRowsTable
          : 44,
      x: dash.length,
      columnFilters,
      columnVisibility,
      globalFilter,
      showGlobalFilter,
      showColumnFilters,
      grouping,
      sorting,
    });

    return dash;
  };

  const removeFromDashboard = async (id: string) => {
    const dash = dashboardList;
    const getItemsFromDash = dash.filter(
      (item) => item.id === selectedDashboard
    )[0]?.dashboard;

    if (getItemsFromDash.length === 1) {
      const removeDashboard = dash.filter(
        (item) => item.id !== selectedDashboard
      );

      setDashboardList(removeDashboard);
      saveSettingsToAuth(removeDashboard, "fundingDashboards");
      setSelectedDashboard("");
      toast.info("Item removed from dashboard");
    } else {
      const removeItemFromDash = getItemsFromDash.filter(
        (item) => item.id !== id
      );

      const updatedDash = dashboardList.map((item) => {
        if (item.id === selectedDashboard) {
          item.dashboard = removeItemFromDash;
        }

        return item;
      });
      toast.info("Item removed from dashboard");
      setDashboardList(updatedDash);
      saveSettingsToAuth(updatedDash, "fundingDashboards");
    }
  };

  const updateDashboardItems = async (dash: dashItems[], type: string) => {
    setDashboardItems(dash);

    const inputDash = "success";

    if (inputDash === "success") {
      if (type !== "updateDash") {
        let toastMsg = "";

        switch (type) {
          case "remove":
            toastMsg = "Table/Graph removed from dashboard.";
            break;
          case "update":
            toastMsg = "Table view saved.";
            break;
          case "merge":
            toastMsg = "Chart merged.";
            break;
          case "unmerge":
            toastMsg = "Chart unmerge.";
            break;
          default:
            toastMsg = `${
              type === "addGraph" ? "Charts" : "Table"
            } added to dashboard.`;
            break;
        }

        toast.success(toastMsg);
      }
    } else {
      toast.error("Something's wrong!");
    }
  };

  const handleFavoriteUnderlying = (data: string, type: string) => {
    if (type === "star") {
      const newFavorites = [...favorites, data];
      saveSettingsToAuth(newFavorites, "fundingFavorites");
      setFavorites(newFavorites);
    } else {
      const filterFavorites = favorites.filter((item) => item !== data);
      saveSettingsToAuth(filterFavorites, "fundingFavorites");
      setFavorites(filterFavorites);
    }
  };

  const convertPercentage = (number: number, toFix: number) => {
    return (number * 100).toFixed(number === 0 ? 0 : toFix) + "%";
  };

  const addToDashboardList = (
    e: DashboardList | null,
    type: string,
    chartType: string | undefined
  ) => {
    if (!e) return;

    const uniqueId = Date.now();
    let error = false;

    // Helper function to update the dashboard
    const updateDashboard = (item: any, newDashboard: any) => ({
      ...item,
      dashboard: addToDashboard(
        type,
        newDashboard,
        chartType,
        `${selectedUnderlying}-${uniqueId}`
      ),
    });

    const filterDash = dashboardList.map((item) => {
      if (e.id === item.id) {
        if (item.dashboard.length >= 20) {
          toast.error("Max of 20 items allowed in a dashboard");
          error = true;
          return item;
        }
        return updateDashboard(item, item.dashboard);
      }
      return item;
    });

    if (error) return;

    if (e.id) {
      setDashboardList(filterDash);
      saveSettingsToAuth(filterDash, "fundingDashboards");
    } else {
      if (dashboardList.length >= 10) {
        toast.error("Max of 10 dashboards allowed");
        return;
      }

      const existingDashboard = dashboardList.find(
        (item) => item.name === e.name.trim()
      );

      let newDashList = [];
      if (existingDashboard) {
        newDashList = dashboardList.map((item) =>
          item.name === e.name.trim()
            ? updateDashboard(item, item.dashboard)
            : item
        );
      } else {
        newDashList = [
          ...dashboardList,
          {
            id: `${uniqueId}`,
            name: e.name.trim(),
            dashboard: addToDashboard(
              type,
              [],
              chartType,
              `${selectedUnderlying}-${uniqueId}`
            ),
          },
        ];
      }

      setDashboardList(newDashList);
      saveSettingsToAuth(newDashList, "fundingDashboards");
    }

    toast.success(`Added to ${e?.name.trim() ?? ""} dashboard.`);
  };

  const updateDashboardName = (dashID: string | null, newName: string) => {
    const filterDash = dashboardList.map((item) => {
      if (item.id === dashID) {
        item.name = newName;
      }
      return item;
    });

    toast.success("Dashboard name updated.");

    setDashboardList(filterDash);
  };

  const deleteItemFromDashboard = (dashID: string | null) => {
    const filterDash = dashboardList.filter((item) => item.id !== dashID);

    setDashboardList(filterDash);
    saveSettingsToAuth(filterDash, "fundingDashboards");

    if (dashID === selectedDashboard) {
      setSelectedDashboard(filterDash[0]?.id);
    }
  };

  const calculateWeightedAverage = (
    data: tableObject[],
    daysToConsider: number,
    weightBinance: number,
    weightBybit: number,
    weightOkx: number,
    newPriceReturn_binance: number,
    newPriceReturn_bybit: number,
    newPriceReturn_okx: number,
    index: number,
    isUnderlying: boolean,
    currentExchangeToggle: exchangeToggle
  ): averageObj | null => {
    if (index === -1) {
      throw new Error("Target date not found in data.");
    }

    const startIndex = index;
    const relevantDays = data.slice(startIndex, daysToConsider);

    if (daysToConsider > data.length) {
      return null;
    }

    let sumBinance = 0,
      sumOkx = 0,
      sumBybit = 0,
      staking = 0,
      sumFunding = { value: 0 };
    let includeBinance: boolean = true,
      includeOkx: boolean = true,
      includeBybit: boolean = true,
      includeStaking: boolean = true;

    if (avgWeight || isUnderlying) {
      sumFunding = {
        value: relevantDays.reduce((accumulator, currentVal: tableObject) => {
          const fundingBinance =
            currentVal.Funding_binance === null ||
            !currentExchangeToggle.binance
              ? null
              : currentVal.Funding_binance;
          const fundingBybit =
            currentVal.Funding_bybit === null || !currentExchangeToggle.bybit
              ? null
              : currentVal.Funding_bybit;
          const fundingOkx =
            currentVal.Funding_okx === null || !currentExchangeToggle.okx
              ? null
              : currentVal.Funding_okx;

          return (
            accumulator +
            ((fundingBinance ?? 0) + (fundingBybit ?? 0) + (fundingOkx ?? 0)) /
              checkFundingNullIndividual(
                fundingBinance,
                fundingBybit,
                fundingOkx
              )
          );
        }, 0),
      };

      relevantDays.forEach((day) => {
        if (day.Staking !== null) {
          staking += day.Staking;
        } else {
          includeStaking = false;
        }
      });
    } else {
      relevantDays.forEach((day) => {
        if (day.Funding_binance !== null) {
          sumBinance += day.Funding_binance;
        } else {
          if (!avgWeight) {
            includeBinance = false;
          }
        }

        if (day.Funding_okx !== null) {
          sumOkx += day.Funding_okx;
        } else {
          if (!avgWeight) {
            includeOkx = false;
          }
        }

        if (day.Funding_bybit !== null) {
          sumBybit += day.Funding_bybit;
        } else {
          if (!avgWeight) {
            includeBybit = false;
          }
        }

        if (day.Staking !== null) {
          staking += day.Staking;
        } else {
          includeStaking = false;
        }
      });
    }

    const checkBinancePrice = !isFinite(newPriceReturn_binance);
    const checkBybitPrice = !isFinite(newPriceReturn_bybit);
    const checkOkxPrice = !isFinite(newPriceReturn_okx);

    const funding =
      avgWeight || isUnderlying
        ? sumFunding
        : calculateWeightNow(
            sumBinance,
            sumBybit,
            sumOkx,
            includeBinance,
            includeBybit,
            includeOkx,
            weightBinance,
            weightBybit,
            weightOkx,
            avgWeight
          );
    const priceReturn = calculateWeightNow(
      !checkBinancePrice ? newPriceReturn_binance : null,
      !checkBybitPrice ? newPriceReturn_bybit : null,
      !checkOkxPrice ? newPriceReturn_okx : null,
      true,
      true,
      true,
      weightBinance,
      weightBybit,
      weightOkx,
      avgWeight
    );

    return {
      funding: funding.value,
      staking: includeStaking ? staking : null,
      priceReturn: priceReturn.value,
    };
  };

  const calculateWeightNow = (
    sumBinance: number | null,
    sumBybit: number | null,
    sumOKX: number | null,
    includeBinance: boolean = true,
    includeBybit: boolean = true,
    includeOkx: boolean = true,
    weightBinance: number = 0,
    weightBybit: number = 0,
    weightOkx: number = 0,
    isAvgWeight: boolean
  ) => {
    let totalSum = 0;

    sumBinance = sumBinance || NaN;
    sumBybit = sumBybit || NaN;
    sumOKX = sumOKX || NaN;

    const isInvalidSum =
      (includeBinance && isNaN(sumBinance) && weightBinance > 0) ||
      (includeBybit && isNaN(sumBybit) && weightBybit > 0) ||
      (includeOkx && isNaN(sumOKX) && weightOkx > 0);

    const exchangeSupportNum: number[] = []; // ARRAY TO STORE THE SUPPORTED EXCHANGE NUMBERS FOR AVERAGE WEIGHT TOGGLED ON
    if (isFinite(sumBinance)) exchangeSupportNum.push(sumBinance);
    if (isFinite(sumBybit)) exchangeSupportNum.push(sumBybit);
    if (isFinite(sumOKX)) exchangeSupportNum.push(sumOKX);

    if (isInvalidSum && !isAvgWeight) {
      // IF VALID AND AVERAGE WEIGHT IS TOGGLED OFF
      return { value: null }; // Return null if any sum is null and its weight is positive
    } else {
      // IT WILL CALCULATE THE WEIGHTED AVERAGE, NAN * NUMBER = NAN ALWAYS
      if (isAvgWeight) {
        // IF AVERAGE WEIGHT IS TOGGLED ON
        const totalSum = exchangeSupportNum.reduce(
          (acc, curr) => acc + curr,
          0
        );
        return { value: totalSum / exchangeSupportNum.length };
      } else {
        // IF AVERAGE WEIGHT IS TOGGLED OFF
        if (weightBinance > 0) {
          totalSum += sumBinance * (weightBinance / 100);
        }

        if (weightBybit > 0) {
          totalSum += sumBybit * (weightBybit / 100);
        }

        if (weightOkx > 0) {
          totalSum += sumOKX * (weightOkx / 100);
        }

        // Check if any sum is included
        if (includeBinance || includeBybit || includeOkx) {
          return { value: totalSum };
        } else {
          return { value: null }; // Return null if all sums are excluded
        }
      }
    }
  };

  // SINGLELY FORMAT DATE TO YYYY-MM-DD
  const formatDate = (date: string) => dayjs(date).format("YYYY-MM-DD");

  const updateTableData = (
    inputDays: number,
    arrayOfData: tableObject[],
    percentA: number,
    percentB: number,
    percentC: number,
    percentD: number,
    percentBinance: number,
    percentBybit: number,
    percentOKX: number,
    isAvgWeight: boolean,
    isAnnualize: boolean,
    isUnderlying: boolean,
    currentExchangeToggle: exchangeToggle
  ) => {
    console.time("updateTableData");

    if (!tableData?.length) {
      return;
    }

    const funding: chartObject[] = [];
    const staking: chartObject[] = [];
    const priceReturn: chartObject[] = [];
    const totalReturn: chartObject[] = [];
    const newTableElements: tableObject[] = [];

    let updateDays = +inputDays;

    const sortedArr = arrayOfData.sort((a: any, b: any) => {
      return new Date(b.Date).getTime() - new Date(a.Date).getTime();
    });

    const nonNullIndex: number[] = [];

    const formattedDates = sortedArr.map((item) => formatDate(item.Date));

    for (let i = 0; i < sortedArr.length; i++) {
      const item = sortedArr[i];
      const previousPriceReturn_binance = sortedArr[i + +inputDays]
        ? sortedArr[i + inputDays].Price_binance
        : 0;
      const previousPriceReturn_bybit = sortedArr[i + inputDays]
        ? sortedArr[i + inputDays].Price_bybit
        : 0;
      const previousPriceReturn_okx = sortedArr[i + inputDays]
        ? sortedArr[i + inputDays].Price_okx
        : 0;
      const newPriceReturn_binance =
        +item.Price_binance / +previousPriceReturn_binance - 1;
      const newPriceReturn_bybit =
        +item.Price_bybit / +previousPriceReturn_bybit - 1;
      const newPriceReturn_okx = +item.Price_okx / +previousPriceReturn_okx - 1;

      const percentX = isAvgWeight ? 33.3 : percentBinance;
      const percentY = isAvgWeight ? 33.3 : percentBybit;
      const percentZ = isAvgWeight ? 33.3 : percentOKX;
      const obj: tableObject = { ...item };
      const index = i;

      const weightedAverageFunding = calculateWeightedAverage(
        sortedArr,
        updateDays,
        percentX,
        percentY,
        percentZ,
        newPriceReturn_binance,
        newPriceReturn_bybit,
        newPriceReturn_okx,
        index,
        isUnderlying,
        currentExchangeToggle
      );
      updateDays++;
      const multiplier = isAnnualize ? 365 / inputDays : 1;
      let sumStaking = weightedAverageFunding?.staking ?? null;
      let sumFunding = weightedAverageFunding?.funding ?? null;
      let priceReturn = weightedAverageFunding?.priceReturn ?? null;
      let WeightedSumFunding: number = 0;
      let WeightedSumStaking: number = 0;
      let WeightedSumPriceReturn: number = 0;

      sumStaking = (sumStaking ?? NaN) * multiplier;
      sumFunding = (sumFunding ?? NaN) * multiplier;
      priceReturn = priceReturn ?? NaN;

      const isStakingNumber = isFinite(sumStaking);
      const isFundingNumber = isFinite(sumFunding);
      const isPriceReturnNumber = isFinite(priceReturn);

      if (isStakingNumber && percentA !== 0) {
        WeightedSumStaking = (percentA / 100) * sumStaking;
      }
      if (isFundingNumber && percentB !== 0) {
        WeightedSumFunding = (percentB / 100) * sumFunding;
      }
      if (isPriceReturnNumber && percentC !== 0) {
        WeightedSumPriceReturn = (percentC / 100) * multiplier * priceReturn;
      }

      if (
        (percentA !== 0 && !isStakingNumber) ||
        (percentB !== 0 && !isFundingNumber) ||
        (percentC !== 0 && !isPriceReturnNumber)
      ) {
        obj.TotalReturn = NaN;
      } else {
        obj.TotalReturn =
          WeightedSumFunding +
          WeightedSumStaking +
          WeightedSumPriceReturn +
          percentD / 100;
      }

      obj.SumStaking = sumStaking;
      obj.Funding = sumFunding;
      obj.PriceReturn = priceReturn;

      if (
        !isNaN(obj.TotalReturn) ||
        !isNaN(obj.Funding) ||
        !isNaN(obj.SumStaking) ||
        !isNaN(obj.PriceReturn)
      ) {
        nonNullIndex.push(i);
      }

      newTableElements.push(obj);
    }

    const nonNulltTableElements: tableObject[] = nonNullIndex.length
      ? newTableElements.slice(
          nonNullIndex[0],
          nonNullIndex[nonNullIndex.length - 1] + 1
        )
      : newTableElements;

    if (nonNulltTableElements.length && !isUnderlying) {
      for (let i = 0; i < nonNulltTableElements.length; i++) {
        const item = nonNulltTableElements[i];
        const checkerFunding = isFinite(+item.Funding);
        const checkerStaking = isFinite(+item.SumStaking);
        const checkerPriceReturn = isFinite(+item.PriceReturn);
        const checkerTotalReturn = isFinite(+item.TotalReturn);

        if (
          checkerFunding ||
          checkerStaking ||
          checkerPriceReturn ||
          checkerTotalReturn
        ) {
          funding.push({
            data: checkerFunding ? +item.Funding * 100 : 0.0,
            date: formattedDates[i],
          });

          staking.push({
            data: checkerStaking ? +item.SumStaking * 100 : 0.0,
            date: formattedDates[i],
          });

          priceReturn.push({
            data: checkerPriceReturn ? +item.PriceReturn * 100 : 0.0,
            date: formattedDates[i],
          });

          totalReturn.push({
            data: checkerTotalReturn ? +item.TotalReturn * 100 : 0.0,
            date: formattedDates[i],
          });
        }
      }

      setFundingChart(funding);
      setStakingChart(staking);
      setPriceReturnChart(priceReturn);
      setTotalReturnChart(totalReturn);
    }
    console.timeEnd("updateTableData");
    setLoading(false);
    return newTableElements;
  };

  return (
    <MyContext.Provider
      value={{
        fundingData,
        togglePrice,
        tableData,
        dashboardItems,
        underlying,
        selectedUnderlying,
        favorites,
        fundingInputs,
        underlyingValues,
        columnFilters,
        columnVisibility,
        globalFilter,
        showGlobalFilter,
        showColumnFilters,
        grouping,
        sorting,
        dashLoading,
        annualize,
        fundingChart,
        stakingChart,
        priceReturnChart,
        totalReturnChart,
        xAxis,
        tableHeight,
        graphHeight,
        dashboardList,
        selectedGraphTable,
        selectedDashboard,
        areChanges,
        processedFundingData,
        avgWeight,
        exchangeToggle,
        loading,
        fundingFields,
        setFundingFields,
        setLoading,
        setDashLoading,
        calcValBasedExchange,
        setExchangeToggle,
        checkFundingNullIndividual,
        setAvgWeight,
        convertPercentage,
        checkFundingNull,
        updateTableData,
        setProcessedFundingData,
        setAreChanges,
        deleteItemFromDashboard,
        updateDashboardName,
        setSelectedDashboard,
        setSelectedGraphTable,
        addToDashboardList,
        setDashboardList,
        resizeUpdateDashboard,
        setXAxis,
        setFundingChart,
        setStakingChart,
        setPriceReturnChart,
        setTotalReturnChart,
        setAnnualize,
        setColumnFilters,
        setColumnVisibility,
        setGlobalFilter,
        setShowGlobalFilter,
        setShowColumnFilters,
        setGrouping,
        setSorting,
        handleFavoriteUnderlying,
        setFavorites,
        setSelectedUnderlying,
        setUnderlying,
        setTogglePrice,
        removeFromDashboard,
        addToDashboard,
        setDashboardItems,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useFundingDataContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
